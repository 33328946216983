<template>
	<div class="switching-esim container">
		<div class="flex">
			<div class="tabs">
				<input type="radio" id="radio-1" name="tabs" :checked="current === 0" @change="onChangeTabHandler(0)" />
				<label class="tab" for="radio-1">IOS</label>
				<input type="radio" id="radio-2" name="tabs" :checked="current === 1"
							@change="onChangeTabHandler(1)" />
				<label class="tab" for="radio-2">Android</label>
				<span class="glider"></span>
			</div>
		</div>
		<a-carousel ref="carousel" :before-change="onChangeCarouselHandler">
			<div class="tab-content">
				<a-row type="flex" :gutter="[16, 16]">
					<a-col :xs="12" :lg="8" v-for="(item, index) in iosImage" :key="index">
						<div class="item-text" v-html="item.text"></div>
						<img v-lazy="item.img" :key="item.img">
					</a-col>
				</a-row>
			</div>
			<div class="tab-content">
				<a-row type="flex" :gutter="[16, 16]">
					<a-col :xs="12" :lg="8" v-for="(item, index) in androidImage" :key="index">
						<div class="item-text" v-html="item.text"></div>
						<img v-lazy="item.img" :key="item.img">
					</a-col>
				</a-row>
			</div>
		</a-carousel>
	</div>
</template>
<script>
import { Carousel } from 'ant-design-vue';
export default {
	components: {
		[Carousel.name]: Carousel,
	},
	data() {
		return {
			current: 0,
			iosImage:[],
			androidImage:[],
			ios:{
				en:[
					{ text:this.$t('esimchange.ios_1'),img:'https://esim.qn.triproaming.cn/esimchange/ios/en-1.png' },
					{ text:this.$t('esimchange.ios_2'),img:'https://esim.qn.triproaming.cn/esimchange/ios/en-2.png' },
					{ text:this.$t('esimchange.ios_3'),img:'https://esim.qn.triproaming.cn/esimchange/ios/en-3.png' },
					{ text:this.$t('esimchange.ios_4'),img:'https://esim.qn.triproaming.cn/esimchange/ios/en-4.png' },
				],
				cn:[
					{ text:this.$t('esimchange.ios_1'),img:'https://esim.qn.triproaming.cn/esimchange/ios/cn-1.png' },
					{ text:this.$t('esimchange.ios_2'),img:'https://esim.qn.triproaming.cn/esimchange/ios/cn-2.png' },
					{ text:this.$t('esimchange.ios_3'),img:'https://esim.qn.triproaming.cn/esimchange/ios/cn-3.png' },
					{ text:this.$t('esimchange.ios_4'),img:'https://esim.qn.triproaming.cn/esimchange/ios/cn-4.png' },
				],
				tw:[
					{ text:this.$t('esimchange.ios_1'),img:'https://esim.qn.triproaming.cn/esimchange/ios/tw-1.png' },
					{ text:this.$t('esimchange.ios_2'),img:'https://esim.qn.triproaming.cn/esimchange/ios/tw-2.png' },
					{ text:this.$t('esimchange.ios_3'),img:'https://esim.qn.triproaming.cn/esimchange/ios/tw-3.png' },
					{ text:this.$t('esimchange.ios_4'),img:'https://esim.qn.triproaming.cn/esimchange/ios/tw-4.png' },
				]
			},
			android:{
				en:[
					{ text:this.$t('esimchange.android_1'),img:'https://esim.qn.triproaming.cn/esimchange/android/en-1.png' },
					{ text:this.$t('esimchange.android_2'),img:'https://esim.qn.triproaming.cn/esimchange/android/en-2.png' },
					{ text:this.$t('esimchange.android_3'),img:'https://esim.qn.triproaming.cn/esimchange/android/en-3.png' },
					{ text:this.$t('esimchange.android_4'),img:'https://esim.qn.triproaming.cn/esimchange/android/en-4.png' },
				],
				cn:[
					{ text:this.$t('esimchange.android_1'),img:'https://esim.qn.triproaming.cn/esimchange/android/cn-1.png' },
					{ text:this.$t('esimchange.android_2'),img:'https://esim.qn.triproaming.cn/esimchange/android/cn-2.png' },
					{ text:this.$t('esimchange.android_3'),img:'https://esim.qn.triproaming.cn/esimchange/android/cn-3.png' },
					{ text:this.$t('esimchange.android_4'),img:'https://esim.qn.triproaming.cn/esimchange/android/cn-4.png' },
				],
				tw:[
					{ text:this.$t('esimchange.android_1'),img:'https://esim.qn.triproaming.cn/esimchange/android/tw-1.png' },
					{ text:this.$t('esimchange.android_2'),img:'https://esim.qn.triproaming.cn/esimchange/android/tw-2.png' },
					{ text:this.$t('esimchange.android_3'),img:'https://esim.qn.triproaming.cn/esimchange/android/tw-3.png' },
					{ text:this.$t('esimchange.android_4'),img:'https://esim.qn.triproaming.cn/esimchange/android/tw-4.png' },
				],
			}
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init(){
			const langMap = { 'zh-cn': 'cn', 'en': 'en', 'zh-tw': 'tw' };
			const lang = langMap[this.$i18n.locale]
			this.iosImage = this.ios[lang] 
			this.androidImage = this.android[lang] 
		},
		onChangeCarouselHandler(_from, to){
			this.current = to
		},
		onChangeTabHandler(current) {
			this.current = current
			this.$refs.carousel.goTo(current)
		},
	},
}
</script>
<style lang="scss" scoped>
.switching-esim {
	padding-top: 20px;
	padding-bottom: 20px;
	.tabs {
		width: 100%;
		height: 36px;
		display: flex;
		align-items: center;
		position: relative;
		border-radius: 10px;
		background-color: #F3F3F3;
		* {
			z-index: 2;
		}
		.glider {
			position: absolute;
			display: flex;
			width: calc(100%/2);
			height: 36px;
			z-index: 1;
			border-radius: 8px;
			transition: 0.25s ease-out;
			background-color: $color;
		}

		.tab {
			user-select: none;
			cursor: pointer;
			color: #222222;
			font-size: 14px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(100%/2);
			height: 100%;
			transition: color 0.15s ease-in;
		}

		input[type="radio"] {
			display: none;

			&:checked {
				&+label {
					color: #fff;
				}
			}
		}

		input[id="radio-1"] {
			&:checked {
				&~.glider {
					transform: translateX(0%);
				}
			}
		}

		input[id="radio-2"] {
			&:checked {
				&~.glider {
					transform: translateX(100%);
				}
			}
		}
	}

	.tab-content {
		margin: 20px 0;
		/deep/ .item-text{
			user-select: none;
			margin-bottom: 10px;
			display: inline-flex;
			span:first-child{
				margin-right: 4px;
			}
			b{
				color: #222222;
			}
		}
	}
}
</style>